import '../css/app.css';
import './bootstrap';

import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createApp, h } from 'vue';
import { createPinia } from 'pinia';
import dayjs from './plugins/dayjs';
import { createI18n } from 'vue-i18n';
import localeMessages from './vue-i18n-locales.generated';
import { flare } from '@flareapp/flare-client';
import { flareVue } from '@flareapp/flare-vue';
import formKitTheme from '@/formkitTheme';
import { createAutoAnimatePlugin } from '@formkit/addons';
import { nl } from '@formkit/i18n';
import { generateClasses } from '@formkit/themes';
import {Ziggy} from "./ziggy";
import { defaultConfig as FKdc, plugin as FKp } from '@formkit/vue';
import {ZiggyVue} from './plugins/ziggy';

if (import.meta.env.PROD) {
    flare.light();
}
const pinia = createPinia();


createInertiaApp({
    progress: { color: '#FFF', delay: 500 },
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const currentLocale = props.initialPage.props.locale;

        const i18n = createI18n({
            legacy: false,
            locale: currentLocale,
            fallbackLocale: 'en',
            messages: localeMessages,
        });

        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(pinia)
            .use(flare)
            .use(ZiggyVue, {...Ziggy, locale: currentLocale})
            .use(dayjs, {locale: currentLocale})
            .use(i18n)
            .use(
                FKp,
                FKdc({
                    config: {
                        classes: generateClasses(formKitTheme),
                    },
                    plugins: [createAutoAnimatePlugin({duration: 150})],
                    locales: {nl},
                    locale: "nl",
                    globalInjection: true,
                })
            )
            .mount(el);
    },
});


function messageToHTMLPlugin(node) {
    const icon =
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="13.818" viewBox="0 0 16 13.818"><path id="warning_FILL0_wght400_GRAD0_opsz48" d="M10.018,11.436ZM2,17.818,10,4l8,13.818Zm7.527-4.145h1.091V9.6H9.527Zm.545,2.018a.529.529,0,1,0-.391-.155A.53.53,0,0,0,10.073,15.691ZM3.891,16.727H16.109L10,6.182Z" transform="translate(-2 -4)" fill="#c30000"/></svg>';

    node.on('created', () => {
        const original = node.props.definition.schema;
        node.context.warningIcon = icon;
        node.props.definition.schema = (extensions) => {
            if (!extensions.message) {
                extensions.message = {
                    attrs: {
                        innerHTML: '$warningIcon + " " + $message.value',
                    },
                    children: null,
                };
            }
            return original(extensions);
        };
    });
}
